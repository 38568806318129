import {KTCard, KTCardBody} from '_metronic/helpers'
import Logo from 'common/components/Logo'
import Spinner from 'common/components/Spinner'
import {useCallback, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {toast} from 'react-toastify'
import {
  calculateAllTenantStats,
  getAllTenants,
  getAllTenantStats,
  getAllTenantStatsProgress,
  getOnlineUserStats,
} from 'setup/redux/Actions/School/Stats/stats'
import DailyChart from './components/DailyChart'
import Platform from './components/Platform'
import BranchWiseOnlineUsers from './components/BranchWiseOnlineUsers'
import Swal from 'sweetalert2'
import {Alert} from 'react-bootstrap'
import StudentParentPlatform from './components/StudentParentPlatform'
import RegisteredUsers from './components/RegisteredUsers'
import OnlineUsers from './components/OnlineUsers'
import {getTenantName} from 'common/HelperFunctions'
import {Select} from 'common/components/Fields'
import Button from 'common/components/Button'

let statsProgressInterval: any = null

const Stats = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const tenant = getTenantName()

  const [selectedTenant, setSelectedTenant] = useState<string | undefined>(tenant)
  const [tenantOptions, setTenantOptions] = useState<{label: string; value: string}[]>([])
  const [loadingTenants, setLoadingTenants] = useState(false)

  const [submittingCalculateStats, setSubmittingCalculateStats] = useState(false)
  const [statsProgress, setStatsProgress] = useState('')
  const [statsProgressError, setStatsProgressError] = useState('')

  const [apiKey, setApiKey] = useState('')
  const [loading, setLoading] = useState(true)
  const [stats, setStats] = useState<any>([])
  const [dailyPlatformSeries, setDailyPlatformSeries] = useState<
    ApexAxisChartSeries | ApexNonAxisChartSeries
  >([])

  const loadStats = useCallback(() => {
    setLoading(true)

    const onSuccess = (res: any) => {
      setStats(res.result)
      setDailyPlatformSeries([
        {
          name: 'Logged In Users',
          data: res.result.dailyStats.map((stat: any) => ({
            x: stat.date,
            y: stat.count,
          })),
        },
      ])
      setLoading(false)
    }

    const onFailure = (err: any) => {
      toast.error(intl.$t({id: 'GENERAL.SOMETHING_WENT_WRONG'}))
      setLoading(false)
    }

    dispatch(getOnlineUserStats(apiKey, onSuccess, onFailure, selectedTenant))
  }, [apiKey, dispatch, intl, selectedTenant])

  const loadAllTenantStats = useCallback(() => {
    setLoading(true)

    const onSuccess = (res: any) => {
      setStats(res.result)
      setDailyPlatformSeries([
        {
          name: 'Logged In Users',
          data: res.result.dailyStats.map((stat: any) => ({
            x: stat.date,
            y: stat.count,
          })),
        },
      ])
      setLoading(false)
    }

    const onFailure = (err: any) => {
      toast.error(intl.$t({id: 'GENERAL.SOMETHING_WENT_WRONG'}))
      setLoading(false)
    }
    dispatch(getAllTenantStats(apiKey, onSuccess, onFailure))
  }, [apiKey, dispatch, intl])

  const loadTenants = useCallback(() => {
    setLoadingTenants(true)

    const onSuccess = (res: any) => {
      const tenants = res.result.data
      const options = tenants.map((tenant: any) => ({
        label: `${tenant.school_name} (${tenant.app_name})`,
        value: tenant.app_name.split('.')[0],
      }))
      options.push({label: 'LGS (lgs.ilmveristy.net)', value: 'lgs'})
      setTenantOptions([{label: 'All Tenants', value: 'all'}, ...options])
      setLoadingTenants(false)
    }

    const onFailure = (err: any) => {
      toast.error(intl.$t({id: 'GENERAL.SOMETHING_WENT_WRONG'}))
      setLoadingTenants(false)
    }
    dispatch(getAllTenants(apiKey, {sort: {school_name: 'asc'}}, onSuccess, onFailure))
  }, [apiKey, dispatch, intl])

  const handleCalculateStats = () => {
    setSubmittingCalculateStats(true)
    setStatsProgress('')
    setStatsProgressError('')
    const onSuccess = (res: any) => {
      const message = res.result.message
      toast.success(message)

      statsProgressInterval = setInterval(getStatsCalculationProgress, 1000)
    }
    const onFailure = () => {
      toast.error(intl.$t({id: 'GENERAL.SOMETHING_WENT_WRONG'}))
      setSubmittingCalculateStats(false)
    }
    dispatch(calculateAllTenantStats(apiKey, onSuccess, onFailure))
  }

  const getStatsCalculationProgress = () => {
    const onSuccess = (res: any) => {
      setStatsProgress(res.result.progress)

      if (res.result.progress === '100%') {
        clearInterval(statsProgressInterval)
        statsProgressInterval = null
        setSubmittingCalculateStats(false)
      }
    }
    const onFailure = (err: any) => {
      setStatsProgressError(err?.message || intl.$t({id: 'GENERAL.SOMETHING_WENT_WRONG'}))

      clearInterval(statsProgressInterval)
      statsProgressInterval = null
      setSubmittingCalculateStats(false)
    }

    dispatch(getAllTenantStatsProgress(apiKey, onSuccess, onFailure))
  }

  useEffect(() => {
    Swal.fire({
      title: 'Enter API Key',
      input: 'text',
      inputPlaceholder: 'Your API Key',
      showCancelButton: false,
      confirmButtonText: 'Submit',
      allowOutsideClick: false,
      preConfirm: (key) => {
        if (!key) {
          Swal.showValidationMessage('API Key is required')
        }
        return key
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setApiKey(result.value)
      }
    })
  }, [])

  useEffect(() => {
    if (apiKey) {
      if (tenant === 'demo') {
        loadTenants()
      }

      if (selectedTenant === 'all') {
        loadAllTenantStats()
      } else {
        loadStats()
      }
    }
  }, [apiKey, loadAllTenantStats, loadStats, loadTenants, selectedTenant, tenant])

  if (!apiKey) {
    return (
      <div style={{height: '90vh'}}>
        <KTCard className='h-100'>
          <KTCardBody>
            <Alert variant='danger'>
              <Alert.Heading>API Key is required</Alert.Heading>
              <p>Please enter your API Key to continue</p>
            </Alert>
          </KTCardBody>
        </KTCard>
      </div>
    )
  }

  return (
    <>
      {selectedTenant !== 'all' && (
        <div className='center mb-5'>
          <Logo showBranchLogo={false} />
        </div>
      )}

      {tenant === 'demo' && (
        <KTCard className='mb-3'>
          <KTCardBody className='row'>
            <div className='col-md-6'>
              <Select
                label='Tenant'
                options={tenantOptions}
                value={selectedTenant}
                onChange={(value) => setSelectedTenant(value)}
                isLoading={loadingTenants}
              />
            </div>

            <div className='col-md-6 text-end'>
              {statsProgressError && (
                <Alert variant='danger' className='text-start'>
                  {statsProgressError}
                </Alert>
              )}

              <Button
                onClick={handleCalculateStats}
                loading={submittingCalculateStats}
                disabled={submittingCalculateStats}
              >
                <i className='fa fa-refresh' />
                Refresh Stats
              </Button>
              {statsProgress && !statsProgressError && (
                <span className='badge badge-light-info ms-3'>{statsProgress}</span>
              )}
            </div>
          </KTCardBody>
        </KTCard>
      )}

      {loading && (
        <KTCard>
          <KTCardBody>
            <div className='center p-5'>
              <Spinner color='primary' />
            </div>
          </KTCardBody>
        </KTCard>
      )}

      {!loading && (
        <div className='row gy-5'>
          <div className='col-md-6'>
            <RegisteredUsers stats={stats.registeredUsers} />
          </div>

          <div className='col-md-6'>
            <OnlineUsers stats={stats.onlineUsers} />
          </div>

          <div className={selectedTenant !== 'all' ? 'col-md-6' : 'col-12'}>
            {stats.platformStats && <Platform stats={stats.platformStats} className='h-100' />}
          </div>

          {selectedTenant !== 'all' && (
            <div className='col-md-6'>
              {stats.parentStudentBranchStats && (
                <BranchWiseOnlineUsers stats={stats.parentStudentBranchStats} className='h-100' />
              )}
            </div>
          )}

          <div className='col-12'>
            <StudentParentPlatform stats={stats.parentStudentPlatformLoginStats} />
          </div>

          <div className='col-12'>
            {dailyPlatformSeries && (
              <DailyChart dailyPlatformSeries={dailyPlatformSeries} className='h-100' />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default Stats
