import {getTenantDomain} from 'common/HelperFunctions'
import {
  GET_ALL_TENANT_STATS,
  GET_ALL_TENANTS,
  GET_ONLINE_USER_STATS,
} from './ActionTypes/ApiActionTypes'

export const getOnlineUserStats = (apiKey, onSuccess, onFailure, tenantName) => {
  const header2 = {
    'x-api-key': apiKey,
  }

  if (tenantName) {
    header2.domain = getTenantDomain(tenantName)
  }

  return {
    type: GET_ONLINE_USER_STATS,
    payload: {
      apiUrl: 'school/stats/online-users',
      getString: '',
      useBaseURL: tenantName !== 'lgs',
      useLgsURL: tenantName === 'lgs',
      requestType: 'GET',
      header: 'application/json',
      header2,
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const getAllTenantStats = (apiKey, onSuccess, onFailure) => {
  return {
    type: GET_ALL_TENANT_STATS,
    payload: {
      apiUrl: 'school/stats/all-tenants',
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      header2: {
        'x-api-key': apiKey,
      },
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const calculateAllTenantStats = (apiKey, onSuccess, onFailure) => {
  return {
    type: GET_ALL_TENANT_STATS,
    payload: {
      apiUrl: 'school/stats/calculate-all',
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      header2: {
        'x-api-key': apiKey,
      },
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const getAllTenantStatsProgress = (apiKey, onSuccess, onFailure) => {
  return {
    type: GET_ALL_TENANT_STATS,
    payload: {
      apiUrl: 'school/stats/calculate-all/progress',
      getString: '',
      useBaseURL: true,
      requestType: 'GET',
      header: 'application/json',
      header2: {
        'x-api-key': apiKey,
      },
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}

export const getAllTenants = (apiKey, body, onSuccess, onFailure) => {
  return {
    type: GET_ALL_TENANTS,
    payload: {
      apiUrl: 'school/stats/tenants',
      getString: '',
      body,
      useBaseURL: true,
      requestType: 'POST',
      header: 'application/json',
      header2: {
        'x-api-key': apiKey,
      },
      reduxActionType: '',
      onSuccess,
      onFailure,
    },
  }
}
