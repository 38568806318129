import {KTCard, KTCardBody} from '_metronic/helpers'
import {FC} from 'react'
import {CardText, CardTitle, OverlayTrigger, Table, Tooltip} from 'react-bootstrap'

type Props = {
  stats: {
    branchId: number
    branchName: string
    totalStudents: number
    onlineStudents: number
    totalParents: number
    onlineParents: number
  }[]
} & React.HTMLAttributes<HTMLDivElement>

const BranchWiseOnlineUsers: FC<Props> = ({stats, ...props}) => {
  return (
    <KTCard {...props}>
      <KTCardBody>
        <CardTitle className='mb-5'>
          <CardText>
            Parent Student Branch Stats
            <OverlayTrigger
              overlay={
                <Tooltip style={{textAlign: 'justify'}}>
                  The number of parents may vary from the actual count because parents are recorded
                  in each branch based on their children's relationships. If a parent has children
                  in multiple branches, they are counted in each of those branches. As a result, the
                  total number of parents may differ from the actual number.
                </Tooltip>
              }
            >
              <i className='fa fa-info-circle cursor-pointer float-end'></i>
            </OverlayTrigger>
          </CardText>
        </CardTitle>

        <Table responsive bordered striped hover className='scroll-y' style={{maxHeight: '550px'}}>
          <thead className='bg-body sticky-top'>
            <tr className='fw-bolder'>
              <th>Branch Id</th>
              <th>Branch Name</th>
              <th className='text-end'>Total Students</th>
              <th className='text-end'>Online Students</th>
              <th className='text-end'>Total Parents</th>
              <th className='text-end'>Online Parents</th>
            </tr>
          </thead>
          <tbody>
            {stats.length === 0 ? (
              <tr>
                <td colSpan={6}>No data</td>
              </tr>
            ) : (
              <>
                {stats.map((stat: any, index: number) => (
                  <tr key={index}>
                    <td>{stat.branchId}</td>
                    <td>{stat.branchName}</td>
                    <td className='text-end'>{stat.totalStudents}</td>
                    <td className='text-end'>{stat.onlineStudents}</td>
                    <td className='text-end'>{stat.totalParents}</td>
                    <td className='text-end'>{stat.onlineParents}</td>
                  </tr>
                ))}
                <tr className='fw-bolder'>
                  <td></td>
                  <th scope='row'>Total</th>
                  <td className='text-end'>
                    {stats.reduce((acc, curr) => acc + curr.totalStudents, 0)}
                  </td>
                  <td className='text-end'>
                    {stats.reduce((acc, curr) => acc + curr.onlineStudents, 0)}
                  </td>
                  <td className='text-end'>
                    {stats.reduce((acc, curr) => acc + curr.totalParents, 0)}
                  </td>
                  <td className='text-end'>
                    {stats.reduce((acc, curr) => acc + curr.onlineParents, 0)}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </KTCardBody>
    </KTCard>
  )
}

export default BranchWiseOnlineUsers
