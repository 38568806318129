import {KTCard, KTCardBody, KTSVG} from '_metronic/helpers'
import {DEVICE_TYPES, USER_TYPES_NUMERIC} from 'common/constants'
import {getDeviceTypeIcon, getDeviceTypeLabel} from 'common/helpers'
import {FC, useMemo} from 'react'
import {CardTitle, Table} from 'react-bootstrap'
import {useIntl} from 'react-intl'

type Stats = {
  device_type: number
  user_type: number
  count: number
}[]

type TableProps = {
  stats: Stats
  heading: string
}

const StatsTable: FC<TableProps> = ({stats, heading}) => {
  const intl = useIntl()
  const hasTranslation = !!intl.messages[heading]

  const total = useMemo(() => stats.reduce((acc, curr) => acc + curr.count, 0), [stats])
  const android = useMemo(
    () =>
      stats.reduce(
        (acc, curr) =>
          acc +
          ([DEVICE_TYPES.ANDROID, DEVICE_TYPES.ANDROID_NEW, DEVICE_TYPES.HYBRID_ANDROID].includes(
            curr.device_type
          )
            ? curr.count
            : 0),
        0
      ),
    [stats]
  )
  const ios = useMemo(
    () =>
      stats.reduce(
        (acc, curr) =>
          acc +
          ([DEVICE_TYPES.IOS, DEVICE_TYPES.IOS_NEW].includes(curr.device_type) ? curr.count : 0),
        0
      ),
    [stats]
  )
  const web = useMemo(
    () =>
      stats.reduce(
        (acc, curr) =>
          acc +
          ([DEVICE_TYPES.HYBRID, DEVICE_TYPES.WEB].includes(curr.device_type) ? curr.count : 0),
        0
      ),
    [stats]
  )

  return (
    <KTCard>
      <KTCardBody>
        <CardTitle>{hasTranslation ? intl.$t({id: heading}) : heading}</CardTitle>

        <Table responsive hover striped bordered>
          <thead>
            <tr className='fw-bolder'>
              <th>Platform</th>
              <th className='text-end'>Total</th>
            </tr>
          </thead>
          <tbody>
            {stats.length === 0 ? (
              <tr>
                <td colSpan={2}>No data</td>
              </tr>
            ) : (
              <>
                <tr>
                  <td className='d-flex align-items-center'>
                    <div className='symbol symbol-45px w-40px me-5'>
                      <span className='symbol-label bg-lighten'>
                        <KTSVG
                          path={getDeviceTypeIcon(DEVICE_TYPES.ANDROID)}
                          className='svg-icon-1'
                        />
                      </span>
                    </div>
                    <span>{intl.$t({id: getDeviceTypeLabel(DEVICE_TYPES.ANDROID)})}</span>
                  </td>
                  <td className='text-end'>{android.toLocaleString()}</td>
                </tr>

                <tr>
                  <td className='d-flex align-items-center'>
                    <div className='symbol symbol-45px w-40px me-5'>
                      <span className='symbol-label bg-lighten'>
                        <KTSVG path={getDeviceTypeIcon(DEVICE_TYPES.IOS)} className='svg-icon-1' />
                      </span>
                    </div>
                    <span>{intl.$t({id: getDeviceTypeLabel(DEVICE_TYPES.IOS)})}</span>
                  </td>
                  <td className='text-end'>{ios.toLocaleString()}</td>
                </tr>

                <tr>
                  <td className='d-flex align-items-center'>
                    <div className='symbol symbol-45px w-40px me-5'>
                      <span className='symbol-label bg-lighten'>
                        <KTSVG path={getDeviceTypeIcon(DEVICE_TYPES.WEB)} className='svg-icon-1' />
                      </span>
                    </div>
                    <span>{intl.$t({id: getDeviceTypeLabel(DEVICE_TYPES.WEB)})}</span>
                  </td>
                  <td className='text-end'>{web.toLocaleString()}</td>
                </tr>

                <tr className='fw-bolder'>
                  <th scope='row'>Total</th>
                  <td className='text-end'>{total.toLocaleString()}</td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </KTCardBody>
    </KTCard>
  )
}

type Props = {
  stats: Stats
}

const StudentParentPlatform: FC<Props> = ({stats}) => {
  const studentStats = useMemo(
    () => stats.filter((stat) => stat.user_type === USER_TYPES_NUMERIC.STUDENT) as Stats,
    [stats]
  )
  const parentStats = useMemo(
    () => stats.filter((stat) => stat.user_type === USER_TYPES_NUMERIC.PARENT) as Stats,
    [stats]
  )

  return (
    <div className='row mt-5'>
      <div className='col-md-6'>
        <StatsTable heading='Online Students distributed platform-wise' stats={studentStats} />
      </div>
      <div className='col-md-6'>
        <StatsTable heading='Online Parents distributed platform-wise' stats={parentStats} />
      </div>
    </div>
  )
}

export default StudentParentPlatform
