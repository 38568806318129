import {getTenantName} from 'common/HelperFunctions'
import {isModuleBetaEnabled} from '.'

export const isHomeworkBetaEnabled = () => {
  return !['nis', 'whales', 'whss', 'lgs', 'risc', 'lyceum', 'bhs', 'linksedu'].includes(
    getTenantName()
  )
}

export const isHomeworkBetaToggleEnabled = () => {
  return isModuleBetaEnabled('homework-beta')
}
