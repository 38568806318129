import {KTCard, KTCardBody} from '_metronic/helpers'
import {getUserTypeString} from 'common/HelperFunctions'
import {FC} from 'react'
import {CardTitle, Table} from 'react-bootstrap'
import {useIntl} from 'react-intl'

type Props = {
  stats: {
    user_type: number
    count: number
  }[]
}

const RegisteredUsers: FC<Props> = ({stats}) => {
  const intl = useIntl()

  return (
    <KTCard>
      <KTCardBody>
        <CardTitle>Registered Users</CardTitle>
        <Table responsive hover striped bordered>
          <thead>
            <tr className='fw-bolder'>
              <th>User Type</th>
              <th className='text-end'>Total</th>
            </tr>
          </thead>
          <tbody>
            {stats.length === 0 ? (
              <tr>
                <td colSpan={2}>No data</td>
              </tr>
            ) : (
              <>
                {stats.map((stat, index: number) => (
                  <tr key={index}>
                    <td>{intl.$t({id: getUserTypeString(stat.user_type)})}</td>
                    <td className='text-end'>{stat.count.toLocaleString()}</td>
                  </tr>
                ))}
                <tr className='fw-bolder'>
                  <th scope='row'>Total</th>
                  <td className='text-end'>
                    {stats.reduce((acc, curr) => acc + curr.count, 0).toLocaleString()}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </KTCardBody>
    </KTCard>
  )
}

export default RegisteredUsers
